import PureMinusIcon from '@components/shared/images/atoms/PureMinusIcon/PureMinusIcon';
import PurePlusIcon from '@components/shared/images/atoms/PurePlusIcon/PurePlusIcon';
import { useTheme } from '@components/theme';
import UserContext from '@context/User/user-context';
import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import ContactUsModal from '@components/shared/modals/ContactUsModal/ContactUsModal';
import env from '@lib/envs/env';
import Copyright from './Copyright/Copyright';
import FollowUs from './FollowUs/FollowUs';
import styles from './MainFooter.module.scss';
import trackLinkClick from './trackLinkClick';

interface ITab {
  title: string;
  navs: INav[];
  className?: string;
}

type IColumn = ITab[];

interface INav {
  title: string;
  link?: string;
  onClick?: () => void;
  newTab?: boolean;
}
const MainFooter = () => {
  const [openTabs, setOpenTabs] = useState<string[]>([]);
  const { user } = useContext(UserContext);

  const [isContactUsOpen, setIsContactUsOpen] = useState(false);

  const onContactUs = () => {
    setIsContactUsOpen(true);
  };

  const columns: IColumn[] = [
    [
      {
        title: 'Company',
        navs: [
          {
            title: 'Careers',
            link: '/careers',
          },
          {
            title: 'Design Careers',
            link: '/interior-design-careers',
          },
          {
            title: 'Reviews',
            link: '/reviews',
          },
          {
            title: 'Pricing',
            link: '/pricing',
          },
          {
            title: 'Blog',
            link: '/blog',
            newTab: true,
          },
          {
            title: 'Press Inquiries',
            link: 'mailto:press@havenly.com'
          }
        ],
      }
    ],
    [
      {
        title: 'Explore',
        navs: [
          {
            title: 'Style Quiz',
            link: '/interior-design-style-quiz',
          },
          {
            title: 'Financing',
            link: '/affirm',
          },
          {
            title: 'Gift Cards',
            link: '/gift-havenly',
          },
          {
            title: 'Refer & Earn',
            link: '/referral_sources/refer_a_friend',
          },
          {
            title: 'Help Center',
            link: 'https://havenly.kustomer.help/',
          },
          {
            title: 'Promotions',
            link: '/current-promotions',
          },
        ],
      }
    ],
    [
      {
        title: 'In-Person Locations',
        className: styles.HIPLocations,
        navs: [
          {
            title: 'Austin',
            link: '/interior-designers/austin',
          },
          {
            title: 'Boston',
            link: '/interior-designers/boston',
          },
          {
            title: 'Charlotte',
            link: '/interior-designers/charlotte',
          },
          {
            title: 'Chicago',
            link: '/interior-designers/chicago',
          },
          {
            title: 'Dallas',
            link: '/interior-designers/dallas',
          },
          {
            title: 'Denver',
            link: '/interior-designers/denver',
          },
          {
            title: 'Houston',
            link: '/interior-designers/houston',
          },
          {
            title: 'Los Angeles',
            link: '/interior-designers/los-angeles',
          },
          {
            title: 'New York',
            link: '/interior-designers/new-york',
          },
          {
            title: 'Philadelphia',
            link: '/interior-designers/philadelphia',
          },
          {
            title: 'Seattle',
            link: '/interior-designers/seattle',
          },
          {
            title: 'Washington DC',
            link: '/interior-designers/washington-dc',
          },
          {
            title: 'View All Locations',
            link: '/in-person#our-markets',
          },
        ],
      }
    ],
    [
      {
        title: 'Our Brands',
        navs: [
          {
            title: 'Interior Define',
            link: 'https://www.interiordefine.com',
          },
          {
            title: 'The Citizenry',
            link: 'https://www.the-citizenry.com/'
          },
          {
            title: 'The Inside',
            link: 'https://www.theinside.com',
          }
        ],
      },
      {
        title: 'Contact',
        navs: [
          {
            title: 'Email hello@havenly.com',
            link: 'mailto:hello@havenly.com',
            onClick: onContactUs,
          },
        ],
      }
    ]
  ];

  if (user?.role === 'designer') {
    columns[0][0].navs.push({
      title: 'Terms and Conditions',
      link: '/interior-designers/terms-and-conditions'
    });
  }

  const handleTabOpen = ({ title }: ITab) => {
    if (openTabs.includes(title)) {
      setOpenTabs(openTabs.filter((tab) => tab !== title));
    } else {
      setOpenTabs([...openTabs, title]);
    }
  };

  return (
    <>
      <ContactUsModal
        visibility={isContactUsOpen}
        user={user!}
        onClose={() => setIsContactUsOpen(false)}
      />
      <div data-test="page-footer" className={styles.Root}>
        <div className={styles.Tabs}>
          {columns.map((column: IColumn) => (
            <div className={column[0].className} key={column[0].title}>
              {column.map((tab: ITab) => (
                <TabItem
                  key={tab.title}
                  tab={tab}
                  onTabClick={() => handleTabOpen(tab)}
                  isOpen={openTabs.includes(tab.title)}
                />
              ))}
            </div>
          ))}
          <FollowUs />
        </div>
        <Copyright />
      </div>
    </>
  );
};

interface ITabItem {
  tab: ITab;
  onTabClick: () => void;
  isOpen: boolean;
}

function TabItem({
  tab, onTabClick, isOpen
}: ITabItem) {
  const theme = useTheme();
  const newTabAttrs = {
    target: '_blank',
    rel: 'noreferrer'
  };

  return (
    <div className={styles.Tab}>
      <TabHeader tab={tab} OnTabClick={onTabClick} isOpen={isOpen} />
      <ul className={styles.TabBody}>
        {tab.navs.map((nav: INav) => (
          <li
            key={nav.title}
            className={classNames(
              styles.TabItem,
              theme.styles.Body,
              isOpen ? styles.open : ''
            )}
          >
            {env.HAVENLY_FEATURE_NEW_CONTACT_US_FORM === true ? (
              // eslint-disable-next-line max-len
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
              <p className={styles.TabNav} onClick={nav.onClick}>
                {nav.title}
              </p>
            ) : (
              <a
                className={styles.TabNav}
                href={nav.link}
                {...(nav.newTab ? newTabAttrs : {})}
                onClick={trackLinkClick(nav.title)}
              >
                {nav.title}
              </a>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

interface ITabHeader {
  tab: ITab;
  isOpen: boolean;
  OnTabClick: () => void;
}
function TabHeader({ tab, isOpen, OnTabClick }: ITabHeader) {
  const theme = useTheme();
  return (
    <>
      <button className={styles.Mobile} type="button" onClick={() => OnTabClick()}>
        <p className={classNames(styles.TabTitle, theme.styles.Title3)}>
          {tab.title}
        </p>
        <span className={styles.Icon}>
          {isOpen ? <PureMinusIcon /> : <PurePlusIcon />}
        </span>
      </button>
      <p className={classNames(styles.Desktop, styles.TabTitle, theme.styles.Title3)}>
        {tab.title}
      </p>
    </>
  );
}

export default MainFooter;
